// Functions first
//@import "bootstrap/functions";

// Variable overrides second
$primary: #9943ec;
$secondary: #1e1e1e;
$warning: #ffd700;
$success: #abe206;
$danger: #ff6d68;
//$enable-shadows: true;
//$prefix: "gs-";
$stroke-100: #d0d5dc;
$gray2: #80818c;
$gray3: #707070;
$without-login: #faf5fe;
$bg-gray: #f5f5f5;
$form-check-input-bg: rgba($primary, 0.2);
$form-check-input-border: 1px solid rgba($primary, 0.4);
$max-items: 100;
$text-color: #4b5563;

// Import Bootstrap SCSS (after defining variables)
@import '~bootstrap/scss/functions'; // Ensure functions are available
@import '~bootstrap/scss/variables'; // Load default variables
@import '~bootstrap/scss/mixins'; // Load mixins

// Now import the full Bootstrap library
@import '~bootstrap/scss/bootstrap';

// //Required Bootstrap imports
// @import "bootstrap/variables";
// @import "bootstrap/variables-dark";
// @import "bootstrap/maps";
// @import "bootstrap/mixins";
// @import "bootstrap/utilities";

// //Optional components
// @import "bootstrap/root";
// @import "bootstrap/reboot";
// @import "bootstrap/type";
// @import "bootstrap/images";
// @import "bootstrap/containers";
// @import "bootstrap/grid";
// @import "bootstrap/tables";
// @import "bootstrap/forms";
// @import "bootstrap/buttons";
// @import "bootstrap/transitions";
// @import "bootstrap/dropdown";
// @import "bootstrap/button-group";
// @import "bootstrap/nav";
// @import "bootstrap/navbar";
// @import "bootstrap/card";
// @import "bootstrap/accordion";
// @import "bootstrap/breadcrumb";
// @import "bootstrap/helpers";
// @import "bootstrap/utilities/api";
// @import "bootstrap/offcanvas";
// @import "bootstrap/close";
// @import "bootstrap/list-group";
// @import "bootstrap/forms/form-control";
// @import "bootstrap/forms/form-check";
// @import "bootstrap/bootstrap.scss";
//@import "css/bootstrap/bootstrap.scss";

@import 'blue-theme';
@import 'dark-theme';
@import 'bordered-theme';
@import 'semi-dark';
@import 'responsive';
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

body,
a,
p {
  font-family: 'Inter', system-ui !important;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
p,
ul li {
  color: $text-color;
}
.mh-56 {
  min-height: 3.5rem;
}
.btn-primary {
  background-color: $primary !important;
  border-color: $primary !important;
  &:hover {
    color: white !important;
  }
}
.fs-12 {
  font-size: 12px !important;
}
.btn-outline-primary {
  color: $primary !important;
  border-color: $primary !important;
  &:hover {
    color: white !important;
    background-color: $primary !important;
  }
}
.text-primary {
  color: $primary !important;
}
.btn-secondary {
  background-color: $secondary !important;
  border-color: $secondary !important;
  &:hover {
    color: white !important;
  }
}
.form-control {
  &:focus {
    box-shadow: 0px 8px 16px 0px rgba($primary, 0.08) !important;
    border-color: rgba($primary, 0.08) !important;
  }
}
.dropdown-item.active,
.dropdown-item:active {
  background-color: $primary !important;
}
a {
  text-decoration: none;
  color: $primary;
}
.fs-18 {
  font-size: 18px;
}
.inter-400 {
  font-family: 'Inter', system-ui;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.inter-500 {
  font-family: 'Inter', system-ui;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.inter-600 {
  font-family: 'Inter', system-ui;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}

.without-login {
  background-color: $without-login;
  font-family: 'Inter', system-ui;
  min-height: 100vh;
}

img {
  max-width: 100%;
}

.fs-14 {
  font-size: 14px;
}

.login-textslider {
  background-color: rgba(0, 0, 0, 3%);

  border-radius: 2rem;

  .login-slider-card {
    padding: 2rem;

    p {
      margin-bottom: 1rem;
    }
  }
}

.seprator {
  position: relative;
  width: 100%;
  text-align: center;

  span {
    display: inline-block;
    position: relative;
    z-index: 1;
    background-color: white;
    padding: 0 1rem;
    color: $gray2;
    font-weight: 500;
  }

  &::after {
    content: '';
    width: 50%;
    height: 1px;
    background-color: $stroke-100;
    position: absolute;
    right: 0;
    top: 50%;
  }

  &::before {
    content: '';
    width: 50%;
    height: 1px;
    background-color: $stroke-100;
    position: absolute;
    left: 0;
    top: 50%;
  }
}

.outsecbtn {
  display: flex !important;
  align-items: center;
  gap: 0.7rem;
  justify-content: center;
  line-height: 2.5rem !important;
  font-weight: 600;
  width: 100%;
  min-height: 3.5rem;

  &:hover {
    background-color: rgba($gray2, 0.2);
    color: $secondary;
  }
}

.access-card {
  background-color: white;
  padding: 2rem;
  border-radius: 2rem;
  height: 100%;
  max-width: 550px;
  margin: 0 auto;
  box-shadow:
    0px 4px 32px 0px rgba(0, 0, 0, 0.03),
    0px 24px 32px -8px rgba(0, 0, 0, 0.07);
}
.access1-card {
  background-color: white;
  padding: 2rem;
  border-radius: 2rem;
  height: 100%;
  max-width: 650px;
  margin: 0 auto;
  box-shadow:
    0px 4px 32px 0px rgba(0, 0, 0, 0.03),
    0px 24px 32px -8px rgba(0, 0, 0, 0.07);
}
.phone-btn {
  position: absolute;
  top: 0px;
  right: 0rem;
  padding: 14px;
  cursor: pointer;
}

.h-52 {
  height: 52px;
}

.swiper-pagination {
  text-align: start !important;
  padding-left: 1.8rem !important;
  bottom: 20px !important;

  .swiper-pagination-bullet-active {
    width: 30px !important;
    border-radius: 20px !important;
    background-color: $primary;
  }
}

@media (max-width: 1366px) {
  body .desk-chat-wrapper .chatroboimg {
    max-width: 92px !important;
  }
}

@media (min-width: 1024px) {
  .main-chat-panel {
    border: 0 !important;
  }
}
.fs-10 {
  font-size: 10px !important;
}
@media (max-width: 428px) {
  body .bg-m-transparent {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .mobile-profile {
    img {
      width: 70px;
      height: 70px;
    }
    h4 {
      font-size: 14px !important;
    }
    i {
      font-size: 11px;
    }
    a {
      font-size: 12px;
    }
    button {
      font-size: 12px;
    }
    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  .feedback {
    .feedback-questions {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)) !important;
      gap: 1rem;

      .question {
        label.top-label {
        }
      }
    }
  }

  .chat-panel {
    .left-side-history {
      right: 0;
      width: 100% !important;
    }
  }
}

@media (max-width: 1024px) {
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 1 !important;
  }
  .search-filter {
    margin-right: 0 !important;
  }
  .chat-panel .left-side-history .history-list li ul li {
    opacity: 1 !important;
  }

  .chat-panel {
    //padding: 1rem;

    grid-template-columns: 1fr !important;
    .left-side-history {
      position: fixed !important;
      background: white;
      padding: 1rem;
      left: -100%;
      top: 0;
      z-index: 10;
      bottom: 0;
      transition: all 0.3s ease-in-out;

      &.showhistory {
        left: 0;
        padding: 1rem;
        background-color: white;
      }
    }

    .main-chat-panel {
      width: 100% !important;
      padding: 0 !important;
      border-left: 0 !important;

      .mobile-chat-header {
        position: fixed;
        left: 0;
        right: 0;
        top: 70px;
        background-color: rgba(233, 206, 252, 1);
        z-index: 1;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          padding: 0 0.5rem;

          li {
            padding: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;

            i {
              color: black;
            }
          }
        }
      }

      .inner-panel {
        .change-instructor {
          display: none;
        }

        .chat-result {
          .left-chat {
            .chat-card {
              max-width: 100% !important;
              width: 100% !important;
              box-shadow: none !important;
              border-radius: 8px !important;
            }

            .profile-icon {
              display: none;
            }
          }

          .right-chat {
            .chat-card {
              max-width: 100%;
              border-radius: 8px !important;
            }
          }
          ul {
            li {
              padding: 1rem 0 !important;
              ul.ansfooter {
                li {
                  padding: 0 !important;
                }
              }
            }
          }
        }

        .chat-suggestion {
          width: 100%;
          max-width: 600px;
          position: relative;
          overflow: hidden;
          margin: auto;
          padding: 0;
          padding-bottom: 1rem;

          h4 {
            display: none;
          }

          ul {
            display: flex;
            transition: transform 0.5s ease-in-out;
            flex-wrap: nowrap;
            gap: 0;

            li {
              min-width: 92%;
              margin: 0 4%;
            }
          }

          .dots {
            text-align: center;
            margin-top: 4px;

            .dot {
              height: 7px;
              width: 7px;
              margin: 0 5px;
              display: inline-block;
              background-color: #bbb;
              border-radius: 50%;
              cursor: pointer;
              transition: background-color 0.3s ease;

              &.active {
                background-color: #717171;
              }
            }
          }
        }
      }
    }
  }

  .progress-line {
    left: 4% !important;
    transform: translateX(-4%) !important;
  }

  .wizard-content .form-control,
  .wizard-content .form-select {
    background-color: white !important;
  }

  .wizard-sidebar {
    padding: 0 0 1rem !important;
    border-right: 0 !important;
  }

  .bg-transparent-mb {
    background-color: transparent !important;
    box-shadow: none !important;
  }

  .wizard-content {
    padding: 0rem !important;
  }

  .multiselectdrop button {
    background-color: white !important;
  }

  .steps-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .progress-background {
      width: 100% !important;
      height: 2px !important;
      top: 50% !important;
      left: 0 !important;
      transform: translateY(-50%);
      transform: translateX(0%) !important;
    }

    .step {
      margin-bottom: 0 !important;

      .step-label {
        display: none;
      }
    }
  }

  .myform-wizard {
    display: flex;
    flex-wrap: wrap;

    .wizard-sidebar {
      width: 100% !important;
    }

    .wizard-content {
      width: 100% !important;
    }
  }

  .chat-content-leftside .chat-left-msg,
  .chat-content-rightside .chat-right-msg {
    max-width: 220px !important;
  }

  .ansfooter li span {
    display: none !important;
  }

  .chatroboimg {
    max-width: 60px;
  }

  .access-card {
    box-shadow: none;
    padding: 0.4rem;
    background: url('../img/mobile-effect.jpg'), white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    border-radius: 0 !important;
  }
  .access1-card {
    box-shadow: none;
    padding: 0.4rem;
    background: url('../img/mobile-effect.jpg'), white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top right;
    border-radius: 0 !important;
  }

  .without-login {
    background-color: white !important;
  }
}

.form-label {
  font-weight: 600;
  font-size: 14px;
  display: block;
  margin-bottom: 7px;
}

.otp-container {
  display: flex;
  gap: 10px;

  .otp-input {
    width: 50px;
    height: 50px;
    text-align: center;
    font-size: 24px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
}

//bootstrap extend

:root,
[data-bs-theme='light'] {
  --bs-blue: #0d6efd;
  --bs-dark-blue: #0a58ca;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #02c27a;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #fc185a;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;

  --bs-success-rgb: 2, 194, 122;
  --bs-danger-rgb: 252, 24, 90;
  --bs-heading-color: #474747;
  --bs-body-color: #5b6166;
  --bs-orange-rgb: rgb(253, 126, 20);
}

[data-bs-theme='dark'] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-2: #181c1f;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #6ea8fe;
  --bs-secondary-text-emphasis: #a7acb1;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #031633;
  --bs-secondary-bg-subtle: #161719;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #084298;
  --bs-secondary-border-subtle: #41464b;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #6ea8fe;
  --bs-link-hover-color: #8bb9fe;
  --bs-link-color-rgb: 110, 168, 254;
  --bs-link-hover-color-rgb: 139, 185, 254;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
  --bs-border-color-transparent: rgba(255, 255, 255, 0);
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}

/* background colors */

.bg-danger {
  background-color: #fc185a;
}

.bg-success {
  background-color: #02c27a;
}

.bg-voilet {
  background-color: #5a4eba;
}

.bg-dark-blue {
  background-color: var(--bs-dark-blue);
}

.bg-cyne {
  background-color: var(--bs-cyan);
}

.bg-indigo {
  background-color: var(--bs-indigo);
}

.bg-purple {
  background-color: var(--bs-purple);
}

.bg-orange {
  background-color: #fd7e14;
}

.bg-orange-light {
  background-color: rgba(253, 125, 20, 0.1);
}

.bg-red {
  background-color: var(--bs-red);
}

.bg-pink {
  background-color: var(--bs-pink);
}

.bg-pinterest {
  background-color: #e60023 !important;
}

.bg-facebook {
  background-color: #0866ff !important;
}

.bg-linkedin {
  background-color: #0072b1 !important;
}

.bg-whatsapp {
  background-color: #25d366 !important;
}

.text-orange {
  color: #fd7e14;
}

.bg-none {
  background-image: none;
}

/* gradient colors */

.bg-grd-primary {
  background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
}

.bg-grd-success {
  background-image: linear-gradient(310deg, #17ad37, #98ec2d) !important;
}

.bg-grd-danger {
  background: linear-gradient(310deg, #ee0979, #ff6a00) !important;
}

.bg-grd-info {
  background-image: linear-gradient(
    310deg,
    #00c6fb 0%,
    #005bea 100%
  ) !important;
}

.bg-grd-warning {
  background: linear-gradient(310deg, #f7971e, #ffd200) !important;
}

.bg-grd-voilet {
  background: linear-gradient(310deg, #3494e6, #ec6ead) !important;
}

.bg-grd-royal {
  background-image: linear-gradient(
    -20deg,
    #2b5876 0%,
    #4e4376 100%
  ) !important;
}

.bg-grd-branding {
  background-image: linear-gradient(#2af598 0%, #009efd 100%) !important;
}

.bg-grd-deep-blue {
  background-image: linear-gradient(
    to right,
    #6a11cb 0%,
    #2575fc 100%
  ) !important;
}

/* components */

body .desk-card {
  margin-bottom: 1.5rem;
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 2.5rem !important;
  height: 100%;
  .card-header {
    padding: 20px !important;
  }
  .card-body {
    padding: 20px !important;
  }
}

.card-header {
  background-color: transparent;
}

/* gradient buttons */

.btn-grd,
.btn-grd:hover,
.btn-grd:focus {
  color: #fff !important;
}

.btn-grd-primary {
  background-image: linear-gradient(310deg, #7928ca, #ff0080) !important;
}

.btn-grd-success {
  background-image: linear-gradient(310deg, #17ad37, #98ec2d) !important;
}

.btn-grd-danger {
  background-image: linear-gradient(310deg, #ee0979, #ff6a00) !important;
}

.btn-grd-info {
  background-image: linear-gradient(
    310deg,
    #00c6fb 0%,
    #005bea 100%
  ) !important;
}

.btn-grd-warning {
  background-image: linear-gradient(310deg, #f76e1e, #ffc400) !important;
}

.btn-grd-voilet {
  background-image: linear-gradient(310deg, #3494e6, #ec6ead) !important;
}

.btn-grd-royal {
  background-image: linear-gradient(
    -20deg,
    #2b5876 0%,
    #4e4376 100%
  ) !important;
}

.btn-grd-branding {
  background-image: linear-gradient(#2af598 0%, #009efd 100%) !important;
}

.btn-grd-deep-blue {
  background-image: linear-gradient(
    to right,
    #6a11cb 0%,
    #2575fc 100%
  ) !important;
}

.btn {
  -webkit-tap-highlight-color: transparent;
  transform: translateY(0);
  --bs-btn-font-weight: 500;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #fc185a;
  --bs-btn-border-color: #fc185a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #ef0e4e;
  --bs-btn-hover-border-color: #ef0e4e;
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ef0e4e;
  --bs-btn-active-border-color: #ef0e4e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fc185a;
  --bs-btn-disabled-border-color: #fc185a;
}

.btn-inverse-danger {
  --bs-btn-color: #fc185a;
  --bs-btn-bg: rgb(252 24 90 / 10%);
  --bs-btn-border-color: rgb(252 24 90 / 0%);
  --bs-btn-hover-color: #fc185a;
  --bs-btn-hover-bg: rgb(252 24 90 / 20%);
  --bs-btn-hover-border-color: rgb(239 14 78 / 0%);
  --bs-btn-focus-shadow-rgb: 225, 83, 97;
  --bs-btn-active-color: #fc185a;
  --bs-btn-active-bg: rgb(252 24 90 / 20%);
  --bs-btn-active-border-color: rgb(252 24 90 / 0%);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #fc185a;
  --bs-btn-disabled-border-color: #fc185a;
}

.btn-outline-danger {
  --bs-btn-color: #fc185a;
  --bs-btn-border-color: #fc185a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #fc185a;
  --bs-btn-hover-border-color: #fc185a;
  --bs-btn-focus-shadow-rgb: 220, 53, 69;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #fc185a;
  --bs-btn-active-border-color: #fc185a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fc185a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #fc185a;
  --bs-gradient: none;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #02c27a;
  --bs-btn-border-color: #02c27a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #04a96c;
  --bs-btn-hover-border-color: #04a96c;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #04a96c;
  --bs-btn-active-border-color: #04a96c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #02c27a;
  --bs-btn-disabled-border-color: #198754;
}

.btn-inverse-success {
  --bs-btn-color: #02c27a;
  --bs-btn-bg: rgb(2 194 122 / 10%);
  --bs-btn-border-color: rgb(2 194 122 / 0%);
  --bs-btn-hover-color: #02c27a;
  --bs-btn-hover-bg: rgb(2 194 122 / 20%);
  --bs-btn-hover-border-color: rgb(2 194 122 / 0%);
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #02c27a;
  --bs-btn-active-bg: rgb(2 194 122 / 20%);
  --bs-btn-active-border-color: rgb(2 194 122 / 0%);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #02c27a;
  --bs-btn-disabled-border-color: #198754;
}

.btn-outline-success {
  --bs-btn-color: #02c27a;
  --bs-btn-border-color: #02c27a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #02c27a;
  --bs-btn-hover-border-color: #02c27a;
  --bs-btn-focus-shadow-rgb: 25, 135, 84;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #02c27a;
  --bs-btn-active-border-color: #02c27a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #02c27a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #02c27a;
  --bs-gradient: none;
}

.btn-inverse-primary {
  --bs-btn-color: #0d6efd;
  --bs-btn-bg: rgb(13 110 253 / 10%);
  --bs-btn-border-color: rgb(13 110 253 / 0%);
  --bs-btn-hover-color: #0d6efd;
  --bs-btn-hover-bg: rgb(13 110 253 / 20%);
  --bs-btn-hover-border-color: rgb(13 110 253 / 0%);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #0d6efd;
  --bs-btn-active-bg: rgb(13 110 253 / 20%);
  --bs-btn-active-border-color: rgb(13 110 253 / 0%);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0d6efd;
  --bs-btn-disabled-border-color: #0d6efd;
}

.btn-inverse-info {
  --bs-btn-color: #0dcaf0;
  --bs-btn-bg: rgb(13 202 240 / 10%);
  --bs-btn-border-color: rgb(13 202 240 / 0%);
  --bs-btn-hover-color: #0dcaf0;
  --bs-btn-hover-bg: rgb(13 202 240 / 20%);
  --bs-btn-hover-border-color: rgb(13 202 240 / 0%);
  --bs-btn-focus-shadow-rgb: 11, 172, 204;
  --bs-btn-active-color: #0dcaf0;
  --bs-btn-active-bg: rgb(13 202 240 / 20%);
  --bs-btn-active-border-color: rgb(13 202 240 / 0%);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #0dcaf0;
  --bs-btn-disabled-border-color: #0dcaf0;
}

.btn-inverse-warning {
  --bs-btn-color: #ffc107;
  --bs-btn-bg: rgb(255 193 7 / 10%);
  --bs-btn-border-color: rgb(255 193 7 / 0%);
  --bs-btn-hover-color: #ffc107;
  --bs-btn-hover-bg: rgb(255 193 7 / 20%);
  --bs-btn-hover-border-color: rgb(255 193 7 / 0%);
  --bs-btn-focus-shadow-rgb: 217, 164, 6;
  --bs-btn-active-color: #ffc107;
  --bs-btn-active-bg: rgb(255 193 7 / 20%);
  --bs-btn-active-border-color: rgb(255 193 7 / 0%);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffc107;
  --bs-btn-disabled-bg: #ffc107;
  --bs-btn-disabled-border-color: #ffc107;
}

.btn-inverse-dark {
  --bs-btn-color: #212529;
  --bs-btn-bg: rgb(33 37 41 / 10%);
  --bs-btn-border-color: rgb(33 37 41 / 0%);
  --bs-btn-hover-color: #212529;
  --bs-btn-hover-bg: rgb(33 37 41 / 20%);
  --bs-btn-hover-border-color: rgb(33 37 41 / 0%);
  --bs-btn-focus-shadow-rgb: 66, 70, 73;
  --bs-btn-active-color: #212529;
  --bs-btn-active-bg: rgb(33 37 41 / 20%);
  --bs-btn-active-border-color: rgb(33 37 41 / 0%);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #212529;
  --bs-btn-disabled-bg: #212529;
  --bs-btn-disabled-border-color: #212529;
}

.btn-inverse-secondary {
  --bs-btn-color: #6c757d;
  --bs-btn-bg: rgb(108 117 125 / 10%);
  --bs-btn-border-color: rgb(108 117 125 / 0%);
  --bs-btn-hover-color: #6c757d;
  --bs-btn-hover-bg: rgb(108 117 125 / 20%);
  --bs-btn-hover-border-color: rgb(108 117 125 / 0%);
  --bs-btn-focus-shadow-rgb: 130, 138, 145;
  --bs-btn-active-color: #6c757d;
  --bs-btn-active-bg: rgb(108 117 125 / 20%);
  --bs-btn-active-border-color: rgb(108 117 125 / 0%);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #6c757d;
  --bs-btn-disabled-border-color: #6c757d;
}

.btn-filter,
.btn-filter:hover,
.btn-filter:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: var(--bs-border-color);
}

.nav-danger.nav-tabs .nav-link.active {
  color: #f41127;

  border-color: #f41127 #f41127 var(--bs-border-color-transparent);
}

.nav-success.nav-tabs .nav-link.active {
  color: #17a00e;

  border-color: #17a00e #17a00e var(--bs-border-color-transparent);
}

.nav-warning.nav-tabs .nav-link.active {
  color: #ffc107;

  border-color: #ffc107 #ffc107 var(--bs-border-color-transparent);
}

.nav-pills-danger.nav-pills .nav-link.active {
  color: #fff;

  background-color: var(--bs-danger);
}

.nav-pills-success.nav-pills .nav-link.active {
  color: #fff;

  background-color: var(--bs-success);
}

.nav-pills-warning.nav-pills .nav-link.active {
  color: #000;
  background-color: var(--bs-warning);
}

.nav-tabs .nav-link {
  color: var(--bs-body-color);
}

.nav-pills .nav-link {
  color: var(--bs-body-color);
}

/* checkboxes */

.form-check-success .form-check-input:checked {
  background-color: #15ca20;
  border-color: #15ca20;
}

.form-check-success .form-check-input[type='checkbox']:indeterminate {
  background-color: #15ca20;
  border-color: #15ca20;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-success .form-check-input:focus {
  border-color: #15ca20;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(21, 202, 33, 0.25);
}

.form-check-danger .form-check-input:checked {
  background-color: #fd3550;
  border-color: #fd3550;
}

.form-check-danger .form-check-input[type='checkbox']:indeterminate {
  background-color: #fd3550;
  border-color: #fd3550;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-danger .form-check-input:focus {
  border-color: #fd3550;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(253, 53, 80, 0.25);
}

.form-check-warning .form-check-input:checked {
  background-color: #ffc107;
  border-color: #ffc107;
}

.form-check-warning .form-check-input[type='checkbox']:indeterminate {
  background-color: #ffc107;
  border-color: #ffc107;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-warning .form-check-input:focus {
  border-color: #ffc107;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.25);
}

.form-check-dark .form-check-input:checked {
  background-color: #212529;
  border-color: #212529;
}

.form-check-dark .form-check-input[type='checkbox']:indeterminate {
  background-color: #212529;
  border-color: #212529;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-dark .form-check-input:focus {
  border-color: #212529;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.25);
}

.form-check-secondary .form-check-input:checked {
  background-color: #6c757d;
  border-color: #6c757d;
}

.form-check-secondary .form-check-input[type='checkbox']:indeterminate {
  background-color: #6c757d;
  border-color: #6c757d;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-secondary .form-check-input:focus {
  border-color: #6c757d;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.25);
}

.form-check-info .form-check-input:checked {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.form-check-info .form-check-input[type='checkbox']:indeterminate {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-info .form-check-input:focus {
  border-color: #0dcaf0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.25);
}

.input-icon .form-control {
  padding-left: 2.5rem;
}

.input-icon span {
  font-size: 16px;
  left: 15px;
}

/* 
.material-icons-outlined {
	line-height: inherit;
} */

.input-group-text {
  background-color: transparent;
}

.price-amount {
  font-size: 50px;
}

.font-20 {
  font-size: 20px;
}

.font-13 {
  font-size: 13px;
}

.font-12 {
  font-size: 12px;
}

// metis menu
.metismenu .arrow {
  float: right;
  line-height: 1.42857;
}

*[dir='rtl'] .metismenu .arrow {
  float: left;
}

.metismenu .glyphicon.arrow:before {
  content: '';
}

.metismenu .mm-active > a > .glyphicon.arrow:before {
  content: '';
}

.metismenu .fa.arrow:before {
  content: '';
}

.metismenu .mm-active > a > .fa.arrow:before {
  content: '';
}

.metismenu .ion.arrow:before {
  content: '';
}

.metismenu .mm-active > a > .ion.arrow:before {
  content: '';
}

.metismenu .plus-times {
  float: right;
}

*[dir='rtl'] .metismenu .plus-times {
  float: left;
}

.metismenu .fa.plus-times:before {
  content: '';
}

.metismenu .mm-active > a > .fa.plus-times {
  transform: rotate(45deg);
}

.metismenu .plus-minus {
  float: right;
}

*[dir='rtl'] .metismenu .plus-minus {
  float: left;
}

.metismenu .fa.plus-minus:before {
  content: '';
}

.metismenu .mm-active > a > .fa.plus-minus:before {
  content: '';
}

.metismenu .mm-collapse:not(.mm-show) {
  display: none;
}

.metismenu .mm-collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: 0.35s;
  transition-property: height, visibility;
}

.metismenu .has-arrow {
  position: relative;
}

.metismenu .has-arrow::after {
  position: absolute;
  content: '';
  width: 0.5em;
  height: 0.5em;
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: initial;
  right: 1em;
  transform: rotate(-45deg) translate(0, -50%);
  transform-origin: top;
  top: 50%;
  transition: all 0.3s ease-out;
}

*[dir='rtl'] .metismenu .has-arrow::after {
  right: auto;
  left: 1em;
  transform: rotate(135deg) translate(0, -50%);
}

.metismenu .mm-active > .has-arrow::after,
.metismenu .has-arrow[aria-expanded='true']::after {
  transform: rotate(-135deg) translate(0, -50%);
}

*[dir='rtl'] .metismenu .mm-active > .has-arrow::after,
*[dir='rtl'] .metismenu .has-arrow[aria-expanded='true']::after {
  transform: rotate(225deg) translate(0, -50%);
}

/*# sourceMappingURL=metisMenu.min.css.map */

// mm vertical
.sidebar-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .metismenu {
  display: flex;
  flex-direction: column;
}

.sidebar-nav .metismenu li + li {
  margin-top: 5px;
}

.sidebar-nav .metismenu li:first-child {
  margin-top: 5px;
}

.sidebar-nav .metismenu li:last-child {
  margin-bottom: 5px;
}

.sidebar-nav .metismenu > li {
  display: flex;
  flex-direction: column;
  position: relative;
}

.sidebar-nav .metismenu a {
  position: relative;
  display: block;
  padding: 13px 15px;
  color: #212529;
  transition: all 0.3s ease-out;
  text-decoration: none;
}

.sidebar-nav .metismenu ul a {
  padding: 10px 15px 10px 30px;
}

.sidebar-nav .metismenu ul ul a {
  padding: 10px 15px 10px 45px;
}

.sidebar-nav .metismenu a:hover,
.sidebar-nav .metismenu a:focus,
.sidebar-nav .metismenu a:active,
.sidebar-nav .metismenu .mm-active > a {
  color: #0c63e4;
  text-decoration: none;
}

.sidebar-nav .metismenu .mm-active > a {
  //background: #e7f1ff;
  border-radius: 3px;
}

// perfect scrollbar
.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

.ps__rail-x {
  display: none;
  opacity: 0;
  transition:
    background-color 0.2s linear,
    opacity 0.2s linear;
  -webkit-transition:
    background-color 0.2s linear,
    opacity 0.2s linear;
  height: 15px;
  bottom: 0;
  position: absolute;
}

.ps__rail-y {
  display: none;
  opacity: 0;
  transition:
    background-color 0.2s linear,
    opacity 0.2s linear;
  -webkit-transition:
    background-color 0.2s linear,
    opacity 0.2s linear;
  width: 15px;
  right: 0;
  position: absolute;
}

.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}

.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y,
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y {
  opacity: 0.6;
}

.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-x:focus,
.ps .ps__rail-x:hover,
.ps .ps__rail-y.ps--clicking,
.ps .ps__rail-y:focus,
.ps .ps__rail-y:hover {
  background-color: #eee;
  opacity: 0.9;
}

.ps__thumb-x {
  background-color: #aaa;
  border-radius: 6px;
  transition:
    background-color 0.2s linear,
    height 0.2s ease-in-out;
  -webkit-transition:
    background-color 0.2s linear,
    height 0.2s ease-in-out;
  height: 6px;
  bottom: 2px;
  position: absolute;
}

.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition:
    background-color 0.2s linear,
    width 0.2s ease-in-out;
  -webkit-transition:
    background-color 0.2s linear,
    width 0.2s ease-in-out;
  width: 6px;
  right: 2px;
  position: absolute;
}

.ps__rail-x.ps--clicking .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x:hover > .ps__thumb-x {
  background-color: #999;
  height: 11px;
}

.ps__rail-y.ps--clicking .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y:hover > .ps__thumb-y {
  background-color: #999;
  width: 11px;
}

@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

//pace
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: linear-gradient(to right, #7928ca, #ff0080, #04e09a, #e0d504);
  -o-border-image: linear-gradient(to right, #7928ca, #ff0080, #04e09a, #e0d504)
    1;
  border-image: linear-gradient(to right, #7928ca, #ff0080, #04e09a, #e0d504) 1;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 3px;
}

.pace .pace-progress-inner {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow:
    0 0 10px #0d6efd,
    0 0 5px #0d6efd;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0, -4px);
  -moz-transform: rotate(3deg) translate(0, -4px);
  -ms-transform: rotate(3deg) translate(0, -4px);
  -o-transform: rotate(3deg) translate(0, -4px);
  transform: rotate(3deg) translate(0, -4px);
}

.pace .pace-activity {
  display: block;
  position: fixed;
  z-index: 2000;
  top: 15px;
  right: 15px;
  width: 20px;
  height: 20px;
  border: solid 3px transparent;
  border-top-color: #0d6efd;
  border-left-color: #0d6efd;
  border-radius: 10px;
  -webkit-animation: pace-spinner 0.4s linear infinite;
  -moz-animation: pace-spinner 0.4s linear infinite;
  -ms-animation: pace-spinner 0.4s linear infinite;
  -o-animation: pace-spinner 0.4s linear infinite;
  animation: pace-spinner 0.4s linear infinite;
}

@-webkit-keyframes pace-spinner {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes pace-spinner {
  0% {
    -moz-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes pace-spinner {
  0% {
    -o-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-ms-keyframes pace-spinner {
  0% {
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes pace-spinner {
  0% {
    transform: rotate(0);
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.desk-chat-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  .chat-header,
  .chat-footer {
    position: relative;
  }
  .chatroboimg {
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .chat-content {
    position: relative;
    height: 100%;
    overflow: auto;
    padding: 1rem;
    max-height: 280px;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;

    @for $i from 1 through $max-items {
      div:nth-child(#{$i}) {
        order: $max-items - $i + 1;
      }
    }
  }
}

// form wizard

.wizard-sidebar {
  padding: 1.5rem;

  position: sticky;
  top: 0;

  border-right: 1px solid rgba($primary, 0.15);
}

.steps-container {
  position: relative;

  height: 100%; // Ensure the container covers the full height

  .progress-background {
    position: absolute;
    top: 0;
    left: 6.5%;
    width: 2px;
    background-color: rgba($primary, 0.2);
    height: 100%;
    z-index: 1;
    max-height: 335px;
    transform: translateX(-6.5%);
  }
  .progress-background-admin {
    position: absolute;
    top: 0;
    left: 6.5%;
    width: 2px;
    background-color: rgba($primary, 0.2);
    height: 100%;
    z-index: 1;
    max-height: 280px;
    transform: translateX(-6.5%);
  }

  .progress-line {
    position: absolute;
    top: 0;
    left: 6.5%;
    width: 2px;
    background-color: #53fb26;
    z-index: 2;
    transition: height 0.3s;
    transform: translateX(-6.5%);
  }

  .step {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &.active .step-circle {
      border: 2px solid $secondary;
      color: $secondary;
    }

    .step-circle {
      width: 32px;
      height: 32px;
      border: 2px solid rgba($primary, 0.2);
      color: #fff;
      background-color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      z-index: 3;
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: -1;
        transition: height 0.3s;
        height: 0;
      }

      &.filled {
        border: 3px solid #53fb26;
        color: #53fb26;
        i {
          color: $secondary;
          font-size: 18px;
          font-weight: 900;
        }
      }

      &.filled::before {
        height: 100%;
      }
    }

    .step-label {
      margin-left: 15px;

      font-size: 14px;
    }

    &:not(:last-child)::after {
      content: '';
      position: absolute;
      top: 20px;
      left: 50%;
      width: 2px;
      height: calc(100% - 40px);
      background-color: #6c757d;
      transform: translateX(-50%);
      z-index: 1;
    }
  }
}

.wizard-content {
  .col-form-label {
    font-size: 14px;
    font-weight: 500;
  }

  .form-control,
  .form-select {
    background-color: $bg-gray;
    border-color: #cfcfcf;
  }

  padding: 1.3rem;

  .form-step {
    display: none;

    &.active {
      display: block;
    }
  }

  .progress-bar {
    margin-top: 5px;
    height: 12px;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
    padding: 2px;

    .progress {
      height: 100%;
      background-color: $primary;
      width: 0;
      transition: width 0.3s;
    }
  }
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-7px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(7px);
  }
}

.toast.shake {
  animation: shake 0.7s ease-in-out;
}

.image-container {
  display: flex;
  gap: 20px;

  .image-box {
    position: relative;
    display: inline-block;

    .image-checkbox {
      display: none; // Hide the actual checkbox
    }

    img {
      width: 120px;
      height: 120px;
      border: 2px solid transparent;
      cursor: pointer;
      transition: border 0.3s ease; // Smooth transition for border
    }

    .check-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      font-size: 20px;
      color: black;
      border: 2px solid black;
      background-color: white;
      border-radius: 50%;
      align-items: center;
      justify-content: center;
      display: none;
      width: 28px;
      height: 28px;

      i {
        font-size: 18px;
      }
    }

    // When the checkbox is checked, apply these styles

    .image-checkbox:checked ~ .check-icon {
      display: flex;
    }
  }
}

.multiselectdrop {
  width: 100%;

  button {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: $bg-gray;
    color: #000;

    &::after {
      margin-left: auto;
      float: right;
    }
  }
}

.border-primary {
  border: 1px solid rgba($primary, 0.15) !important;
}

.myform-wizard {
  display: flex;

  .wizard-sidebar {
    width: 290px;
  }

  .wizard-content {
    width: calc(100% - 290px);
  }
}

.upload-profile-image {
  width: 120px;
  height: 120px;
  border: 1px solid rgba($primary, 0.15);
  border-radius: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;

  i {
    font-size: 30px;
  }
}

.chat-panel {
  height: calc(100vh - 75px);
  display: grid;
  grid-template-columns: 320px 1fr;

  max-width: 100%;
  overflow: hidden;
  position: relative;

  .left-side-history {
    padding: 1rem 0 0rem 1rem;
    background-color: rgba($primary, 0.02);
    border-right: 1px solid rgba($primary, 0.1);

    .search-filter {
      position: relative;
      margin-right: 1rem;
      input {
        background-color: transparent;
        border: 1px solid $primary;
      }

      button {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;

        img {
          max-width: 100%;
        }
      }
    }

    .history-label {
      font-weight: 600;
      margin: 1rem 0;
    }

    .history-list {
      list-style: none;
      padding: 0;
      margin: 0;
      overflow: auto;
      height: calc(100vh - 186px);
      li {
        padding-left: 48px;
        position: relative;
        display: flex;
        flex-direction: column;

        margin-bottom: 1rem;
        padding-bottom: 1rem;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0rem;
          background: var(
            --line-bg,
            linear-gradient(
              90deg,
              rgba(15, 8, 22, 0) 0%,
              rgba(15, 8, 22, 0.16) 25%,
              rgba(15, 8, 22, 0.16) 75%,
              rgba(15, 8, 22, 0) 100%
            )
          );
          height: 1px;
          width: 100%;
        }

        &::before {
          content: '';
          position: absolute;
          background-image: url('../icons/search-circle.svg');
          width: 38px;
          height: 38px;
          background-size: 100%;
          left: 0;
        }

        .date {
          font-size: 10px;
          font-weight: 500;
          text-transform: capitalize;
          margin-bottom: 0.2rem;
        }

        .question {
          font-size: 14px;
          font-weight: 500;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-width: 75%;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
          display: flex;
          align-items: center;
          gap: 5px;
          position: absolute;
          right: 0.7rem;
          top: 50%;
          transform: translateY(-50%);

          li {
            align-items: center;
            border-radius: 30px;
            display: flex;
            height: 32px;
            justify-content: center;
            margin-bottom: 0;
            opacity: 0;
            padding: 0;
            width: 32px;

            i {
              font-size: 18px;
            }

            &::before {
              display: none;
            }

            &.active {
              opacity: 1;
              color: $primary;
            }

            &::after {
              display: none;
            }
            &:hover {
              background-color: #9943ec1a;
            }
          }
        }

        &:hover {
          ul {
            li {
              opacity: 1;
              transition: all 0.2s ease-in-out;
            }
          }
        }
      }
    }
  }

  .main-chat-panel {
    padding: 1rem 1rem 1.7rem;
    height: 100%;

    .inner-panel {
      display: flex;
      flex-direction: column;
      height: 100%;

      .chat-result {
        height: 100%;
        max-height: calc(100vh - 210px);
        margin-bottom: 5px;
        position: relative;

        display: flex;
        overflow: auto;
        .welcome-box {
          margin: 0;
          width: 100%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          img {
            max-width: 250px;
          }
        }

        ul {
          width: 100%;
          padding: 0;
          margin: 0;
          list-style: none;
          display: flex;

          flex-direction: column-reverse;
          overflow: auto;
          @for $i from 1 through $max-items {
            li:nth-child(#{$i}) {
              order: $max-items - $i + 1;
            }
          }

          li {
            &:last-child {
              padding-bottom: 1rem;
            }
            span.anstext {
              color: $primary;
              display: flex;
              gap: 0.3rem;
              align-items: center;
              margin-bottom: 0.6rem;

              i {
                font-size: 14px;
              }
            }

            ul {
              flex-direction: row;
              li {
                padding: 0;
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }

            .profile-icon {
              width: 40px;

              img {
                display: block;
              }
            }

            .chat-card {
              padding: 0.8rem;
              width: calc(100% - 44px);
              max-width: 60%;
              background-color: white;
              font-size: 14px;
              border-radius: 20px;
              border: 1px solid rgba(10, 5, 15, 0.1);
              box-shadow: 0px 12px 24px 0px rgba(11, 6, 16, 0.08);

              .ansfooter {
                display: flex;
                align-items: center;
                list-style: none;
                padding: 0;
                margin: 0;
                gap: 1rem;
                position: relative;

                li {
                  display: flex;
                  align-items: center;
                  gap: 0.3rem;
                  font-size: 12px;
                  cursor: pointer;

                  i {
                    font-size: 14px;
                  }
                }

                &::before {
                  background: linear-gradient(
                    90deg,
                    rgba(10, 5, 15, 0) 0%,
                    rgba(10, 5, 15, 0.16) 25%,
                    rgba(10, 5, 15, 0.16) 75%,
                    rgba(10, 5, 15, 0) 100%
                  );
                  content: '';
                  height: 2px;
                  width: 100%;
                  position: absolute;
                  left: 0rem;
                  right: 0rem;
                  top: -0.7rem;
                }
              }
            }

            &.right-chat {
              justify-content: end;

              .chat-card {
                background-color: $primary;

                span.anstext {
                  color: white;
                }

                color: white;

                .chat-card-body {
                  p {
                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }
              }
            }

            display: flex;
            align-items: end;
            gap: 1rem;
            padding: 1rem 1rem 0;
          }
        }
      }
    }

    .chat-input {
      border-radius: 100px;

      position: relative;

      input {
        box-shadow: 0px 8px 16px 0px rgba(10, 5, 15, 0.08);
        font-size: 15px;
        min-height: 50px;

        &::placeholder {
          font-size: 15px;
        }
      }

      button {
        position: absolute;
        right: 6px;
        top: 6px;
        bottom: 6px;
        width: 42px;
        height: auto;
        border-radius: 4px;

        i {
          width: 24px;
        }
      }
    }

    .chat-suggestion {
      padding: 0 2.5rem;

      h4 {
        font-size: 22px;
        position: relative;

        &::after {
          background: linear-gradient(
            90deg,
            rgba(15, 8, 22, 0.24) 0%,
            rgba(15, 8, 22, 0) 100%
          );
          width: 82%;
          height: 1px;
          content: '';
          display: block;
          position: absolute;
          left: 18%;
          top: 15px;
        }
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1.5rem;
        flex-wrap: wrap;

        li {
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          background-color: white;
          border-radius: 12px;
          box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.08);
          padding: 0.5rem 0.7rem;
          font-size: 14px;
        }
      }
    }

    .change-instructor {
      margin-top: 2rem;

      select {
        max-width: 200px;
        border-radius: 50px;
        font-size: 14px;
        height: 44px;
      }
    }
  }

  &.new-chat {
    .main-chat-panel {
      .inner-panel {
        max-width: 1120px;
      }

      .welcome-box {
        text-align: center;
        margin: auto;
        h1 {
          font-size: 24px;
        }
      }
    }
  }
}

.new-chat {
  .left-side-history {
    display: none;
  }

  .main-chat-panel {
    border-left: 0 !important;
  }
}

.feedback {
  h1 {
    margin: 1rem 0;
    font-size: 24px;
  }

  .feedback-questions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(600px, 1fr));
    gap: 1rem;

    .question {
      padding: 1rem;
      border-radius: 20px;
      border: 1px solid rgba(10, 5, 15, 0.11);
      background: rgba(255, 255, 255, 0.48);
      box-shadow: 0px 12px 24px 0px rgba(11, 6, 16, 0.08);
      label.top-label {
        margin-bottom: 1rem;
        font-weight: 500;
      }
    }
  }
}

//accordion design
.accordion-item:not(:first-of-type) {
  border-top: 1px solid rgba(91, 97, 102, 0.2) !important;
}
.accor-des {
  .accordion-item {
    margin-bottom: 1rem;

    button {
      font-size: 15px;
      &:focus,
      &:active {
        box-shadow: none;
      }
    }
    .accordion-body {
      font-size: 14px;
      p {
        font-size: 14px;
      }
    }
  }
}

a {
  text-decoration: none !important;
}
.bg-primary-20 {
  background-color: #f2e5ff !important;
}
.link-underline {
  position: relative;
  font-weight: 500;
  text-decoration: none;
  &:after {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -4px;

    height: 1.8px;
    background-color: #000;
    content: '';
  }
}
.show-seprate {
  border: 1px solid rgba(170, 47, 135, 0.1);
  border-radius: 1rem;
  padding: 1rem;
}

.chat-header2 {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: end;
  margin-bottom: 1rem;
  flex-direction: row-reverse;
}

.ps__rail-y {
  background-color: rgba($primary, 0.1) !important;
  width: 7px !important;
}

.ps__thumb-y {
  background-color: $primary !important;
  width: 7px !important;
  transition: background-color 0.3s;
  right: 0 !important;
}

.ps__rail-x {
  background-color: $primary !important;
  width: 7px !important;
}
.ps__thumb-x {
  background-color: $primary;
  transition: background-color 0.3s;
}

.accordion-button:not(.collapsed) {
  color: #3d1b5e !important;
  background-color: #ebd9fb !important;
  box-shadow: inset 0 1px 0 #ebd9fb !important;
}

.form-check-input:checked {
  background-color: $primary !important;
  border-color: $primary !important;
}

.loader-box {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  background-color: rgba(white, 0.7);
  backdrop-filter: blur(1px);
  .searchtext {
    font-size: 1.5rem;
    font-weight: 500;
    color: black;
  }
  .spinner {
    width: 44.8px;
    height: 44.8px;
    animation: spinner-y0fdc1 2s infinite ease;
    transform-style: preserve-3d;
  }

  .spinner > div {
    background-color: rgba(153, 67, 236, 0.2);
    height: 100%;
    position: absolute;
    width: 100%;
    border: 2.2px solid #9943ec;
  }

  .spinner div:nth-of-type(1) {
    transform: translateZ(-22.4px) rotateY(180deg);
  }

  .spinner div:nth-of-type(2) {
    transform: rotateY(-270deg) translateX(50%);
    transform-origin: top right;
  }

  .spinner div:nth-of-type(3) {
    transform: rotateY(270deg) translateX(-50%);
    transform-origin: center left;
  }

  .spinner div:nth-of-type(4) {
    transform: rotateX(90deg) translateY(-50%);
    transform-origin: top center;
  }

  .spinner div:nth-of-type(5) {
    transform: rotateX(-90deg) translateY(50%);
    transform-origin: bottom center;
  }

  .spinner div:nth-of-type(6) {
    transform: translateZ(22.4px);
  }

  @keyframes spinner-y0fdc1 {
    0% {
      transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
    }

    50% {
      transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
    }

    100% {
      transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
    }
  }
}

.chat-loader-box {
  position: absolute;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  background-color: rgba(white, 0.7);
  backdrop-filter: blur(1px);
  .searchtext {
    font-size: 1.5rem;
    font-weight: 500;
    color: black;
  }
  .spinner {
    width: 44.8px;
    height: 44.8px;
    animation: spinner-y0fdc1 2s infinite ease;
    transform-style: preserve-3d;
  }

  .spinner > div {
    background-color: rgba(153, 67, 236, 0.2);
    height: 100%;
    position: absolute;
    width: 100%;
    border: 2.2px solid #9943ec;
  }

  .spinner div:nth-of-type(1) {
    transform: translateZ(-22.4px) rotateY(180deg);
  }

  .spinner div:nth-of-type(2) {
    transform: rotateY(-270deg) translateX(50%);
    transform-origin: top right;
  }

  .spinner div:nth-of-type(3) {
    transform: rotateY(270deg) translateX(-50%);
    transform-origin: center left;
  }

  .spinner div:nth-of-type(4) {
    transform: rotateX(90deg) translateY(-50%);
    transform-origin: top center;
  }

  .spinner div:nth-of-type(5) {
    transform: rotateX(-90deg) translateY(50%);
    transform-origin: bottom center;
  }

  .spinner div:nth-of-type(6) {
    transform: translateZ(22.4px);
  }

  @keyframes spinner-y0fdc1 {
    0% {
      transform: rotate(45deg) rotateX(-25deg) rotateY(25deg);
    }

    50% {
      transform: rotate(45deg) rotateX(-385deg) rotateY(25deg);
    }

    100% {
      transform: rotate(45deg) rotateX(-385deg) rotateY(385deg);
    }
  }
}

@-moz-document url-prefix() {
  * {
    scrollbar-width: auto;
    scrollbar-color: rgba($primary, 0.2) rgba($primary, 0.02);
  }
}

::-webkit-scrollbar-track {
  border-radius: 2px;
  background-color: rgba($primary, 0);
}

::-webkit-scrollbar {
  width: 8px;
  background-color: rgba($primary, 0);
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;

  background-color: rgba($primary, 0.15);
}

.fix-btn-inline {
  position: relative;
  input {
    padding-right: 32px;
  }
  button {
    position: absolute;
    right: 3px;
    top: 3px;
    bottom: 3px;
    background-color: $primary;
    color: white;
    border: 0;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.logoui {
  display: flex;
  align-items: center;
  gap: 1rem;
  img {
    width: 32px;
  }
  span {
    font-size: 24px;
    font-weight: bold;
  }
}

.addcomingsoon {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  &:hover {
    transition: all 0.3s ease-in-out;
    &::after {
      position: absolute;
      transition: all 0.3s ease-in-out;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(white, 0.8);
      content: 'Coming Soon!';
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(5px);
      font-weight: 500;
    }
  }
}

.MuiInputBase-root {
  border: 0 !important;
}

.main-wrapper
  .main-content
  .page-breadcrumb
  .breadcrumb-item
  + .breadcrumb-item::before {
  display: none !important;
}

.MuiFormLabel-root {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.freechatbox {
  position: fixed;
  background-color: rgba(black, 0.3);
  z-index: 1000;
  bottom: 0;
  top: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: end;
  justify-content: end;
  flex-direction: column;
  .profilechatinner {
    max-width: 500px;
    background-color: $bg-gray;
    width: 100%;
    border-radius: 1.5rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    .chat-box {
      padding: 0.5rem !important;
      height: 400px;
      overflow: auto;
      .message-bubble {
        padding: 0.7rem 0.8rem !important;
        box-shadow:
          rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
          rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
        line-height: 1rem;
        border-radius: 8px !important;
        font-size: 15px;
        &.left {
          background-color: white !important;
        }
      }
    }
  }
  .copyright {
    font-size: 10px;
    padding: 0.5rem;
  }
  .proheader {
    display: flex;
    align-items: center;
    padding: 1rem;
    font-weight: 500;
    font-size: 14px;
    .me-auto {
      display: flex;
      gap: 0.8rem;
      line-height: 16px;
    }
  }

  .chatinput-body {
    width: calc(100% - 26px);
    box-shadow:
      rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px !important;
    border-radius: 12px;
    margin: 0 auto 0.8rem;
    .error-text {
      color: $danger;
      font-size: 14px;
      margin-bottom: 0;
      padding: 0.5rem;
    }
    input,
    select {
      border-radius: 12px;
      border: 0;
      min-height: 42px;
    }
  }
}

.card {
  margin-bottom: 1.5rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border: 0 !important;
  border-radius: 1rem !important;
  width: 100%;
  overflow: hidden;
  .card-header {
    padding: 20px !important;
    background-color: rgba(0, 0, 0, 0);
  }
  .card-body {
    padding: 20px !important;
  }
}

// .scrollbar-container {
//   display: flex;
//   flex-direction: column;
//   .sidebar-nav {
//     height: 100%;
//   }
// }

.swiper-container {
  position: relative;
}
.swiper-button-prev,
.swiper-button-next {
  opacity: 0;
  color: $secondary !important;
  transition: opacity 0.3s ease;
  width: 32px !important;
  height: 32px !important;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  &::after {
    font-size: 1.2rem !important;
  }
}

.swiper-container:hover .swiper-button-prev,
.swiper-container:hover .swiper-button-next {
  opacity: 1;
}
.swiper-button-prev {
  left: -10px !important;
}
.swiper-button-next {
  right: -10px !important;
}
.svgwhite {
  path {
    fill: white;
  }
}
.uploadfile {
  padding: 4rem 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #d1d5db;
  border-radius: 8px;
}
.assignbtngrp {
  display: flex;
  gap: 10px;

  button {
    border: 1px solid #e5e7eb !important;
    border-radius: 8px !important;
    color: #4b5563;
    min-height: 86px;
  }
  button[aria-pressed='true'] {
    background-color: rgba($primary, 0.1);
    border: 1px solid rgba($primary, 0.7) !important;
    color: $primary;
  }
}

.fileslistitem {
  background-color: #f9fafb;
}
.pinwi-20 {
  svg {
    width: 20px;
    height: 20px;
    path {
      fill: #6b7280;
    }
  }
}

.css-19kzrtu {
  padding: 1.5rem 0 !important;
}

.overflow-auto {
  overflow: auto;
}
