.institution-charts {
  font-family: 'Roboto', Arial, sans-serif;
  // background-color: #f9fafc;
  border-radius: 12px;
  // box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
  // background-color: #ffffff;
  padding: 16px;
  border-radius: 8px;
  // box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.control-group {
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

.control-group label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: #555;
}

.control-group select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: white;
  font-size: 14px;
  transition: border-color 0.2s;
}

.control-group select:focus {
  border-color: #2196f3;
  outline: none;
}

.tabs-container {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}

.tabs {
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.tab {
  padding: 10px 20px;
  background-color: #f0f0f0;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: 500;
  font-size: 14px;
}

.tab:hover {
  background-color: #e0e0e0;
}

.tab.active {
  background-color: #2196f3;
  color: white;
}

.tab:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tab:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.charts-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
}

.chart-box {
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
  transition:
    transform 0.2s,
    box-shadow 0.2s;
}

.chart-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.chart-box h3 {
  margin-top: 0;
  margin-bottom: 16px;
  color: #333;
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.full-width {
  grid-column: span 2;
}

.chart-container {
  margin-bottom: 24px;
}

.student-activity,
.teacher-activity {
  display: flex;
  flex-direction: column;
}

.chart-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  background-color: #f5f5f5;
  border-radius: 8px;
  color: #666;
  font-weight: 500;
}

@media (max-width: 992px) {
  .charts-grid {
    grid-template-columns: 1fr;
  }

  .full-width {
    grid-column: auto;
  }

  .controls {
    flex-direction: column;
    align-items: stretch;
  }

  .tabs-container {
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .control-group {
    width: 100%;
  }

  .tabs {
    width: 100%;
  }

  .tab {
    flex-grow: 1;
    text-align: center;
  }
}

.subject-performance {
  .percentage-view {
    margin-top: 30px;

    h4 {
      margin-bottom: 15px;
      font-size: 16px;
      color: #2c3e50;
    }
  }

  .percentage-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
  }

  .percentage-card {
    background: #f8f9fa;
    border-radius: 8px;
    padding: 12px;

    h5 {
      margin-top: 0;
      margin-bottom: 10px;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .percentage-bars {
      height: 20px;
      display: flex;
      width: 100%;
      border-radius: 4px;
      overflow: hidden;
    }

    .percentage-bar {
      height: 100%;

      &.low {
        background-color: #ef5350;
      }

      &.medium {
        background-color: #ffa726;
      }

      &.high {
        background-color: #66bb6a;
      }

      &.very-high {
        background-color: #26c6da;
      }
    }

    .percentage-labels {
      display: flex;
      justify-content: space-between;
      margin-top: 5px;
      font-size: 12px;
      color: #7f8c8d;
    }
  }
}
